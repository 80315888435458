import React from "react"
import { Link } from "gatsby-interface"

import Layout from "../../layout"
import SiteHead from "../../head"
import Container from "../../components/container"
import PageWithSidebar from "../../components/page-with-sidebar"
import { DocsSidebarNav } from "../../components/sidebar/docs-sidebar-nav"
import { SkipNavTarget } from "../../components/shared/components/SkipNav"

import allDocs from "../../components/sidebar/all-docs-sidebar.yaml"
import DocsHomeCtaCallout from "../../components/docs/docs-home-cta-callout.js"
import {
  SubsectionList,
  SectionList,
  PageHeader,
  LinkList,
} from "../../components/docs/docs-type-landing-section.js"
import { GatsbyCloudLogo } from "../../components/shared/logos"
import subnav from "./subnav"
import StructuredData from "./structured-data"
import { getOgImage } from "./get-og-image"

export function Head(props) {
  const docsHomepageContent = props.pageContext?.docsHomepageContent
  const documentationType =
    props.pageContext?.documentationType || allDocs[0].subItems.slice(2) // How to Guides
  const isDocsHomepage = !!docsHomepageContent

  const headerContent = isDocsHomepage ? allDocs[0].content : null

  const homepageContent = props.pageContext.docsHomepageContent
  // logic duplicated from component
  const page =
    allDocs[0].subItems.find(item => documentationType.label === item.label) ||
    allDocs[0]
  const title = (!!homepageContent && allDocs[0].content?.title) || page.label

  const description = headerContent?.description || page?.docTypeDescription

  return (
    <SiteHead
      {...props}
      title={title}
      description={description}
      image={getOgImage(props.location)}
    >
      <StructuredData {...props} />
    </SiteHead>
  )
}

export default function DocsTypeTemplate({ location, pageContext }) {
  const docsHomepageContent = pageContext?.docsHomepageContent
  const documentationType =
    pageContext?.documentationType || allDocs[0].subItems.slice(2) // How to Guides
  const isDocsHomepage = !!docsHomepageContent

  const headerContent = isDocsHomepage ? allDocs[0].content : null

  const currentPage =
    allDocs[0].subItems.find(
      subItem => documentationType.label === subItem.label
    ) || allDocs[0]

  const {
    docTypeDescription,
    subItems,
    label,
    title,
    subSections,
    emphasizedColor,
  } = currentPage

  const description = headerContent?.description || docTypeDescription

  const sectionList = isDocsHomepage
    ? // .slice to remove the Quick Start
      allDocs[0].subItems.filter(item => !item.itemType).slice(1)
    : subItems.filter(item => !item.itemType)

  return (
    <Layout subnav={subnav}>
      <PageWithSidebar location={location} SidebarComponent={DocsSidebarNav}>
        <main>
          <SkipNavTarget />
          <PageHeader
            emphasizedColor={emphasizedColor}
            titleText={headerContent?.title || title || label}
            description={description}
            location={location}
          />
          <Container>
            {isDocsHomepage && (
              <DocsHomeCtaCallout
                content={headerContent.gettingStartedCallout}
              />
            )}
            {subSections ? (
              <SubsectionList
                currentPage={currentPage}
                advanced={currentPage.subItems.find(item => item?.advanced)}
              />
            ) : currentPage.subItems.find(item => item?.subItems) ? (
              <SectionList
                sectionList={sectionList}
                isDocsHomepage={isDocsHomepage}
              />
            ) : (
              <LinkList
                items={currentPage.subItems.filter(item => !item.itemType)} // exclude both overview & additional
              />
            )}
            {isDocsHomepage && (
              <div
                css={theme => ({
                  background: theme.colors.blue[90],
                  borderRadius: theme.radii[3],
                  color: theme.colors.whiteFade[90],
                  padding: theme.space[6],
                  marginBottom: theme.space[6],
                  verticalAlign: `middle`,
                  "& > svg": { verticalAlign: `middle` },
                })}
              >
                <GatsbyCloudLogo width={20} height={20} />
                &nbsp; <b>Looking for the Gatsby Cloud documentation?</b> Head
                on over to the{" "}
                <Link
                  href="https://www.gatsbyjs.com/docs/how-to/cloud/"
                  css={theme => ({
                    color: theme.colors.white,
                    ":hover": {
                      color: theme.colors.white,
                    },
                  })}
                >
                  Gatsby Cloud Knowledge Base &rarr;
                </Link>
              </div>
            )}
          </Container>
        </main>
      </PageWithSidebar>
    </Layout>
  )
}
